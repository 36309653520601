import i18next, { Resource, ResourceLanguage, TFunction } from 'i18next';
import { useEffect } from 'react';
import { getLogger } from '../../services/Logger';

const logger = getLogger('pdf/utils/translations');

interface TranslationModule {
  default: Record<string, string>;
}

// Use Vite's glob import feature to load all translations
const translations = import.meta.glob<TranslationModule>(
  '../../locales/*.json',
  {
    eager: true,
  },
);

// Create a map of language codes to translation modules
const languageResources: Resource = Object.fromEntries(
  Object.entries(translations)
    .map(([path, module]): [string, ResourceLanguage] => {
      // Extract language code from path (e.g., '../../locales/en.json' -> 'en')
      const langMatch = /\/([^/]+)\.json$/.exec(path);
      const lang = langMatch?.[1];
      if (!lang) {
        logger.error(`Could not extract language code from path: ${path}`);
        return ['', { translation: {} }];
      }
      return [lang, { translation: module.default }];
    })
    .filter((entry): entry is [string, ResourceLanguage] => entry[0] !== ''),
);

// Create and initialize a single i18n instance
const i18nInstance = i18next.createInstance();
void i18nInstance
  .init({
    resources: languageResources,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  })
  .catch((error) => {
    logger.error('Error initializing i18n:', error);
  });

interface UsePDFTranslationReturn {
  t: TFunction;
  i18n: typeof i18nInstance;
  ready: boolean;
}

/**
 * Hook for using translations in PDF components
 * @param language - The language to use for translations
 * @returns Object containing translation function (t), i18n instance, and ready state
 */
export const usePDFTranslation = (
  language: string,
): UsePDFTranslationReturn => {
  useEffect(() => {
    void i18nInstance.changeLanguage(language).catch((error) => {
      logger.error('Error changing language:', error);
    });
  }, [language]);

  return {
    t: i18nInstance.t.bind(i18nInstance),
    i18n: i18nInstance,
    ready: i18nInstance.isInitialized,
  };
};

// Keep the direct function for non-React contexts
export const getPDFTranslation = (key: string, language: string): string => {
  void i18nInstance.changeLanguage(language).catch((error) => {
    logger.error('Error changing language:', error);
  });
  return i18nInstance.t(key);
};

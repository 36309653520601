import { useEffect, useRef, useState, useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocumentProxy } from 'pdfjs-dist';
import { Box, Text } from '@mantine/core';
import { motion } from 'framer-motion';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min.mjs?worker&url';
import { PDFToolbar } from './PDFToolbar';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface ResumePDFViewerProps {
  blob: Blob | null;
  width?: string | number;
  height?: string | number;
  showToolbar?: boolean;
  className?: string;
  style?: React.CSSProperties;
  isGenerating?: boolean;
}
export const ResumePDFViewer: React.FC<ResumePDFViewerProps> = ({
  blob,
  width = '100%',
  height = '100%',
  showToolbar = true,
  className,
  style,
  isGenerating = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const activeCanvasRef = useRef<HTMLCanvasElement>(null);
  const bufferCanvasRef = useRef<HTMLCanvasElement>(null);
  const [pdfDoc, setPdfDoc] = useState<PDFDocumentProxy | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0,
  });
  const renderTaskRef = useRef<{
    promise: Promise<void>;
    cancel: () => void;
  } | null>(null);
  const resizeTimeoutRef = useRef<NodeJS.Timeout>();

  const cancelRenderTask = useCallback(() => {
    if (renderTaskRef.current) {
      renderTaskRef.current.cancel();
      renderTaskRef.current = null;
    }
  }, []);

  const renderPage = useCallback(
    async (pdf: PDFDocumentProxy, pageNumber: number) => {
      if (
        !activeCanvasRef.current ||
        !bufferCanvasRef.current ||
        !containerRef.current
      )
        return;

      setIsLoading(true);
      cancelRenderTask();

      const activeCanvas = activeCanvasRef.current;
      const bufferCanvas = bufferCanvasRef.current;
      const container = containerRef.current;

      try {
        const page = await pdf.getPage(pageNumber);
        const bufferContext = bufferCanvas.getContext('2d', { alpha: false });

        if (!bufferContext) return;

        const pixelRatio = window.devicePixelRatio || 1;
        const SCALE_FACTOR = 1.5;

        const viewport = page.getViewport({ scale: 1 });
        const containerWidth = container.clientWidth;
        const baseScale = containerWidth / viewport.width;
        const scale = Math.min(baseScale * SCALE_FACTOR, 2);

        const scaledViewport = page.getViewport({ scale: scale * pixelRatio });

        const newWidth = Math.floor(scaledViewport.width);
        const newHeight = Math.floor(scaledViewport.height);
        const displayWidth = Math.floor(newWidth / (pixelRatio * SCALE_FACTOR));
        const displayHeight = Math.floor(
          newHeight / (pixelRatio * SCALE_FACTOR),
        );

        setCanvasDimensions({ width: displayWidth, height: displayHeight });

        // Set dimensions for both canvases
        [activeCanvas, bufferCanvas].forEach((canvas) => {
          canvas.width = newWidth;
          canvas.height = newHeight;
          canvas.style.width = `${displayWidth}px`;
          canvas.style.height = `${displayHeight}px`;
        });

        bufferContext.fillStyle = '#ffffff';
        bufferContext.fillRect(0, 0, newWidth, newHeight);
        bufferContext.imageSmoothingEnabled = true;
        bufferContext.imageSmoothingQuality = 'high';

        const renderTask = page.render({
          canvasContext: bufferContext,
          viewport: scaledViewport,
        });

        renderTaskRef.current = {
          promise: renderTask.promise,
          cancel: () => {
            renderTask.cancel();
            page.cleanup();
          },
        };

        await renderTask.promise;

        if (renderTaskRef.current?.promise === renderTask.promise) {
          renderTaskRef.current = null;
          const activeContext = activeCanvas.getContext('2d', { alpha: false });
          if (activeContext) {
            activeContext.fillStyle = '#ffffff';
            activeContext.fillRect(0, 0, newWidth, newHeight);
            activeContext.drawImage(bufferCanvas, 0, 0);
          }
        }

        page.cleanup();
      } catch (error) {
        if (error instanceof Error && !error.message.includes('cancelled')) {
          console.error('Error rendering page:', error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [cancelRenderTask],
  );

  const handlePageChange = useCallback(
    async (newPage: number) => {
      if (!pdfDoc) return;
      setCurrentPage(newPage);
      await renderPage(pdfDoc, newPage);
    },
    [pdfDoc, renderPage],
  );

  useEffect(() => {
    let isCurrentEffect = true;
    let currentPdf: PDFDocumentProxy | null = null;

    const loadPDF = async () => {
      setIsLoading(true);
      cancelRenderTask();

      if (currentPdf) {
        await currentPdf.destroy();
        currentPdf = null;
        setPdfDoc(null);
      }

      if (!blob) return;

      try {
        const loadingTask = pdfjsLib.getDocument({
          data: await blob.arrayBuffer(),
          cMapPacked: true,
          rangeChunkSize: 65536,
        });

        const pdf = await loadingTask.promise;

        if (!isCurrentEffect) {
          await pdf.destroy();
          return;
        }

        currentPdf = pdf;
        setPdfDoc(pdf);
        await renderPage(pdf, 1);
      } catch (error) {
        console.error('Error loading PDF:', error);
      } finally {
        setIsLoading(false);
      }
    };

    void loadPDF();

    const handleResize = () => {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(() => {
        if (currentPdf) {
          void renderPage(currentPdf, currentPage);
        }
      }, 100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      isCurrentEffect = false;
      cancelRenderTask();
      clearTimeout(resizeTimeoutRef.current);
      if (currentPdf) {
        void currentPdf.destroy();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [blob, cancelRenderTask, renderPage]); // Removed currentPage from dependencies

  const toolbar = showToolbar && pdfDoc && (
    <PDFToolbar
      currentPage={currentPage}
      totalPages={pdfDoc.numPages}
      onPreviousPage={() => void handlePageChange(currentPage - 1)}
      onNextPage={() => void handlePageChange(currentPage + 1)}
    />
  );

  return (
    <Box
      className={className}
      style={{
        width,
        height,
        overflow: 'auto',
        ...style,
      }}
    >
      {toolbar}
      <Box
        ref={containerRef}
        style={{
          border: '1px solid #f0f0f0',
          borderRadius: '0 0 8px 8px',
          position: 'relative',
          background: '#ffffff',
          minHeight: canvasDimensions.height,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <motion.canvas
          ref={activeCanvasRef}
          animate={{ opacity: isLoading || isGenerating ? 0.3 : 1 }}
          transition={{ duration: 0.3 }}
          style={{
            display: 'block',
            maxWidth: '100%',
            height: 'auto',
            backgroundColor: '#ffffff',
            imageRendering: 'crisp-edges',
          }}
        />
        <canvas
          ref={bufferCanvasRef}
          style={{
            display: 'none',
            backgroundColor: '#ffffff',
          }}
        />
        {(isLoading || isGenerating) && (
          <Box
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <Text size="sm" c="dimmed">
                {isGenerating ? 'Generating PDF...' : 'Loading PDF...'}
              </Text>
            </motion.div>
          </Box>
        )}
      </Box>
      {toolbar}
    </Box>
  );
};
